<!--结算单管理（机构代理商）-->
<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">结算单管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="结算单编号" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 90px">结算单编号:</span>
              <el-input
                  v-model="billNo"
                  type="text"
                  clearable
                  size="small"
                  placeholder="请输入结算单编号"
              />
            </div>
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 80px">班级编码:</span>
              <el-input
                  v-model="projectCode"
                  type="text"
                  clearable
                  size="small"
                  placeholder="请输入班级编码"
              />
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 80px">班级名称:</span>
              <el-input
                  v-model="projectName"
                  type="text"
                  clearable
                  size="small"
                  placeholder="请输入班级名称"
              />
            </div>
            <div title="开班单位" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 80px">开班单位:</span>
              <el-input
                  v-model="compName"
                  type="text"
                  clearable
                  size="small"
                  placeholder="请输入开班单位"
              />
            </div>
            <el-button class="bgc-bv" round @click="getList">查询</el-button>
            <el-button class="bgc-bv" round @click="exportList">导出</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="multipleTable"
                :data="dataList"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  width="60"
              />

              <el-table-column
                  label="结算单编号"
                  align="center"
                  prop="billNo"
                  show-overflow-tooltip
                  min-width="140"
              >
              </el-table-column>

              <el-table-column
                  label="班级编码"
                  align="center"
                  show-overflow-tooltip
                  min-width="180"
              >
                <template v-slot="{ row }">
                  <div
                      style="margin: 5px auto"
                      v-for="(item, index) in row.projectList"
                      :key="index"
                  >
                    {{ item.projectCode }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                  label="班级名称"
                  align="center"
                  show-overflow-tooltip
                  min-width="120"
              >
                <template v-slot="{ row }">
                  <div
                      style="margin: 5px auto"
                      v-for="(item, index) in row.projectList"
                      :key="index"
                  >
                    {{ item.projectName }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                  label="开班单位"
                  align="center"
                  show-overflow-tooltip
                  min-width="150"
                  prop="compName"
              >
              </el-table-column>
              <el-table-column
                  label="结算人数"
                  align="center"
                  show-overflow-tooltip
                  min-width="80"
              >
                <template v-slot="{ row }">
                  <div v-for="(item, index) in row.projectList" :key="index">
                    {{ item.settleNum }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                  label="结算金额"
                  align="center"
                  show-overflow-tooltip
                  min-width="80"
              >
                <template v-slot="{ row }">
                  <div v-for="(item, index) in row.projectList" :key="index">
                    {{ item.settlementAmount }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                  label="平台结算金额"
                  align="center"
                  prop="courseMoney"
                  show-overflow-tooltip
                  min-width="100"
              >
                <template v-slot="{ row }">
                  <div v-for="(item, index) in row.projectList" :key="index">
                    {{ item.platformSettlementAmount }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                  label="申请状态"
                  align="center"
                  show-overflow-tooltip
                  min-width="150"
              >
                <template v-slot="{ row }">
                  {{ applicationStatus(row) }}
                </template>
              </el-table-column>

              <el-table-column
                  label="创建日期"
                  align="center"
                  show-overflow-tooltip
                  min-width="150"
                  prop="createTime"
              >
              </el-table-column>

              <el-table-column
                  label="备注"
                  align="center"
                  show-overflow-tooltip
                  min-width="150"
              >
                <template v-slot="{ row }">
                  <el-tooltip
                      effect="dark"
                      :content="row.remark"
                      placement="top"
                  >
                    <span>
                      {{ row.remark }}
                    </span>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                  label="操作"
                  align="center"
                  width="300px"
                  fixed="right"
              >
                <template v-slot="{ row }" class="flexcc">
                  <el-button
                      type="text"
                      style="padding: 0px 5px"
                      size="mini"
                      :disabled="row.auditType != '50'"
                      @click="confirmSettle(row)"
                  >结算单确认</el-button
                  >
                  <el-button
                      type="text"
                      style="padding: 0px 5px"
                      size="mini"
                      @click="upload(row)"
                  >上传附件</el-button
                  >
                  <el-button
                      type="text"
                      style="padding: 0px 5px"
                      size="mini"
                      :disabled="btnState(row)"
                      @click="downloadSettle(row)"
                  >下载结算单</el-button
                  >
                  <el-button
                      type="text"
                      style="padding: 0px 5px"
                      size="mini"
                      :disabled="row.auditType != '01'"
                      @click="withdraw(row)"
                  >撤回</el-button
                  >
<!--                  <el-button-->
<!--                      type="text"-->
<!--                      style="padding: 0px 5px"-->
<!--                      size="mini"-->
<!--                      :disabled="!row.isAllowInvoice"-->
<!--                      @click="applyForInvoicing(row)"-->
<!--                  >申请开票</el-button-->
<!--                  >-->
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
            :apiData="pagination"
            @sizeChange="sizeChange"
            @getData="currentChange"
        />
      </div>
    </div>

    <el-dialog
        title="上传附件"
        width="700px"
        @closed="handleClosed1"
        :visible.sync="dialogVisible1"
    >
      <span style="color: red; font-size: 16px; margin-bottom: 10px"
      >请上传盖章后的结算单，文件格式为JPG或PNG或PDF</span
      >
      <el-input
          style="margin-bottom: 30px"
          type="textarea"
          placeholder="请输入备注"
          resize="none"
          show-word-limit
          maxlength="255"
          :rows="5"
          v-model="remark"
      >
      </el-input>

      <div style="margin: 10 0 10px">
        <el-upload
            class="upload-demo upload-btn upload-btndrag"
            action
            :on-change="uploadChange"
            :show-file-list="false"
            :auto-upload="false"
            multiple
            drag
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
        <div
            v-if="uploadfileList.length"
            style="margin-top: 60px; margin-left: 10px"
        >
          当前选择文件：
          <div style="display: flex; flex-direction: column; margin-top: 10px">
            <span
                style="color: #409eff"
                v-for="(item, index) in uploadfileList"
                :key="index"
            >
              {{ item.fileName }}
              <a @click="lookExl(item)" style="color: #409eff; margin: 0 15px"
              >预览</a
              >
              <a @click="reomveExl(index)" style="color: red; margin: 0 15px"
              >删除</a
              >
            </span>
          </div>
        </div>
        <div v-else>
          <p style="margin-top: 60px; margin-left: 10px">未选择文件</p>
        </div>
      </div>

      <span
          slot="footer"
          class="dialog-footer"
          style="margin-top: 30px; display: flex; justify-content: flex-end"
      >
        <el-button class="bgc-bv" @click="dialogVisible1 = false" type="primary"
        >取 消</el-button
        >
        <el-button class="bgc-bv" type="primary" @click="submit"
        >上 传</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
        title="结算单确认"
        :visible.sync="dialogVisible"
        width="1200px"
        @closed="handleClosed"
    >
      <span style="font-size: 20px; font-weight: 600">班级明细</span>
      <el-table :data="dialogData.projectList" stripe style="width: 100%">
        <el-table-column align="center" type="index" label="序号">
        </el-table-column>
        <el-table-column
            prop="projectCode"
            align="center"
            label="班级编码"
            width="200"
        >
        </el-table-column>
        <el-table-column
            prop="projectName"
            align="center"
            label="班级名称"
            width="200"
        >
        </el-table-column>
        <el-table-column prop="settleNum" align="center" label="结算人数">
        </el-table-column>
        <el-table-column
            prop="settleMethod"
            align="center"
            width="100"
            label="结算方式"
        >
        </el-table-column>
        <el-table-column align="center" width="120" label="公共课/总课时">
          <template v-slot="{ row }">
            {{ `${row.openLessonNum}/${row.totalLessonNum}` }}
          </template>
        </el-table-column>
        <el-table-column
            prop="countrySubsidyStandard"
            align="center"
            label="补贴标准"
        >
        </el-table-column>
        <el-table-column
            prop="settleStandard"
            align="center"
            min-width="200"
            label="结算标准"
        >
        </el-table-column>
        <el-table-column
            prop="settlementAmount"
            align="center"
            label="结算金额"
        >
        </el-table-column>
        <el-table-column
            prop="platformSettlementAmount"
            align="center"
            width="180"
            label="平台结算金额"
        >
        </el-table-column>
      </el-table>
      <span style="font-size: 20px; font-weight: 600; margin: 20px 0"
      >结算单</span
      >
      <el-row class="rowdata">
        <el-col :span="4" class="col-title">付款单位</el-col>
        <el-col :span="8">{{ dialogData.payerName }}</el-col>
        <el-col :span="4" class="col-title">收款单位</el-col>
        <el-col :span="8">{{ dialogData.payeeName }}</el-col>
      </el-row>
      <el-row class="rowdata">
        <el-col :span="4" class="col-title">付款金额</el-col>
        <el-col :span="8">{{
            `￥${dialogData.settlementAmount}元（大写：${dialogData.settlementAmountChinese}）`
          }}</el-col>
        <el-col :span="4" class="col-title">收款账号</el-col>
        <el-col :span="8">
          <span>{{ `开户银行：${dialogData.payeeBank}` }}</span>
          <br />
          <span>{{ `银行账号：${dialogData.payeeAccount}` }}</span>
        </el-col>
      </el-row>
      <el-row class="rowdata">
        <el-col :span="4" class="col-title">收费标准</el-col>
        <el-col :span="20">{{ dialogData.chargesSettlement }}</el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button class="bgc-bv" @click="dialogVisible = false" type="primary"
        >取 消</el-button
        >
        <el-button
            class="bgc-bv"
            type="primary"
            @click="adopt(dialogData.billId, true)"
        >通 过</el-button
        >
        <el-button
            class="bgc-bv"
            type="primary"
            @click="adopt(dialogData.billId, false)"
        >驳 回</el-button
        >
      </span>
    </el-dialog>
    <!-- 预览 -->
    <el-dialog
        title="预览"
        :visible.sync="previewLoding"
        @close="uploadLodClose"
        width="50%"
        top="2%"
        center
    >
      <div class="ovy-a">
        <div
            id="pdf-cert1"
            style="height: 600px"
            v-if="fileType == 'pdf' || fileType == 'PDF'"
        ></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import pdf from "pdfobject";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "SettlementmanagementList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 确认结算单对话框可见性
      dialogVisible: false,
      //   上传附件对话框可见性
      dialogVisible1: false,
      //   预览对话框可见性
      previewLoding: false,
      //   结算单id
      billId: "",
      //   表格数据
      dataList: [],
      //   班级编码
      projectCode: "",
      //   班级名称
      projectName: "",
      //   结算单编号
      billNo: "",
      // 开班单位
      compName: "",
      //   分页器数据
      pagination: {
        current: 1,
        size: 10,
        total: 0,
      },
      //   对话框数据
      dialogData: {},
      //   文件列表
      uploadfileList: [],
      // 备注
      remark: "",
      //   上传图片预览地址
      ImgSrc: "",
      //   上传文件类型
      fileType: "",
      //   上传结算单类型
      docType: "10",
    };
  },
  created() {
    this.getList();
  },
  beforeRouteLeave: resetKeepAlive,
  computed: {
    ...mapGetters({ downloadItems: "getDownloadItems" }),
  },
  methods: {
    // 获取列表数据
    async getList() {
      const { projectCode, projectName, billNo, compName } = this;
      const { current, size } = this.pagination;
      const params = {
        pageNum: current,
        pageSize: size,
        projectCode,
        projectName,
        billNo,
        compName,
      };
      // 如果是查询数据则不需要分页
      if (projectCode || projectName || billNo || compName) {
        delete params.pageNum;
        delete params.pageSize;
      }
      try {
        const result = await this.$post(
            "/biz/new/agent/comp/billPageList",
            params
        );
        this.dataList = result.data.list;
        this.pagination.total = result.data.total;
      } catch (e) {
        return;
      }
    },
    // 判断 下载结算单 按钮状态
    btnState(row) {
      const arr = ["50", "52", "60", "65", "70", "80"];
      if (arr.includes(row.auditType)) return false;
      return true;
    },
    // 申请状态 文字 的显示逻辑
    applicationStatus(row) {
      const { auditType } = row;
      // 平台审核中的数据
      const underReview = ["05", "10", "20", "40", "30", "53", "56"];
      // 审核未通过的数据
      const auditFailed = ["03", "07"];
      // 已完成的数据
      const completed = ["60", "80", "70", "65"];
      if (underReview.includes(auditType)) return "平台审核中";
      if (auditFailed.includes(auditType)) return "审核未通过";
      if (completed.includes(auditType)) return "已完成";
      return this.$setDictionary("FD_AUDIT_TYPE", auditType);
    },
    // 导出
    async exportList() {
      const { projectCode, projectName, billNo, compName } = this;
      try {
        const result = await this.$post("/biz/new/agent/comp/export", {
          projectCode,
          projectName,
          billNo,
          compName,
        });
        const arr = [...result.data];
        for (let item of arr) {
          if (!this.downloadItems.includes(item.taskId)) {
            this.$store.dispatch("pushDownloadItems", item.taskId);
          } else {
            this.$message.warning(`[${item.fileName}]已经申请下载`);
          }
        }
      } catch (e) {
        return;
      }
    },
    // 分页器改变条数
    sizeChange(val) {
      const { pagination } = this;
      pagination.size = val;
      this.getList();
    },
    // 分页器改变当前页
    currentChange(val) {
      const { pagination } = this;
      pagination.current = val;
      this.getList();
    },
    // 确认结算单(详情)
    async confirmSettle(row) {
      this.dialogVisible = true;
      const { billId } = row;
      try {
        const result = await this.$post("/biz/new/agent/comp/getVerifyInfo", {
          billId,
        });
        this.dialogData = result.data;
      } catch (e) {
        return;
      }
    },
    // 通过或者驳回
    async adopt(billId, isPass) {
      let remark = "";
      if (!isPass) {
        // 驳回
        this.$prompt("请输入驳回理由", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPattern: /.+/,
          inputErrorMessage: "请输入驳回理由",
          confirmButtonClass: "confirmButton-feifan",
        }).then(async ({ value }) => {
          remark = value;
          try {
            await this.$post("/biz/new/agent/comp/billConfirm", {
              billId,
              isPass,
              remark,
            });
            this.getList();
            this.dialogVisible = false;
            this.$message.success("操作成功");
          } catch (e) {
            return;
          }
        });
      } else {
        // 通过
        try {
          await this.$post("/biz/new/agent/comp/billConfirm", {
            billId,
            isPass,
          });
          this.getList();
          this.dialogVisible = false;
          this.$message.success("操作成功");
        } catch (e) {
          return;
        }
      }
    },
    // 上传附件
    async upload(row) {
      const { billId } = row;
      this.billId = billId;
      const result = await this.$post("/biz/new/agent/comp/billFileList", {
        billId,
      });
      this.uploadfileList = result.data.fdNewBillFileBOList || [];
      this.remark = result.data.remark;
      this.dialogVisible1 = true;
    },
    // 预览
    lookExl(item) {
      this.fileType = item.fileType;
      this.previewLoding = true;
      if (item.fileType == "pdf" || item.fileType == "PDF") {
        this.$nextTick(() => {
          pdf.embed(item.fileUrl, "#pdf-cert1");
        });
      } else {
        this.ImgSrc = item.fileUrl;
      }
    },
    // 删除上传文件
    reomveExl(index) {
      this.uploadfileList.splice(index, 1);
    },
    // 上传文件改变
    async uploadChange(file) {
      const fileType = ["png", "jpg", "pdf", "jpeg"];
      const size = file.size / 1024 / 1024;
      const extension = file.name.slice(file.name.lastIndexOf(".") + 1);
      if (!fileType.includes(extension.toLowerCase()))
        return this.$message.error("只能上传png,jpg,jpeg,pdf格式文件");
      if (size > 20) return this.$message.error("文件大小不能超过20M");
      const formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      // 上传文件拿到key
      try {
        const result = await this.$Postformat("/sys/upload", formData);
        this.uploadfileList.push({
          billId: this.billId,
          fileName: file.name,
          fileType: extension,
          fileKey: result.data.fileKey,
          fileUrl: result.data.fileURL,
        });
      } catch (e) {
        return;
      }
    },
    // 下载结算单
    async downloadSettle(row) {
      const { billId } = row;
      try {
        const result = await this.$post("/biz/new/agent/comp/downloadBill", {
          billId,
        });
        const url = result.message || "";
        window.open(url);
      } catch (e) {
        return;
      }
    },
    // 撤回
    async withdraw(row) {
      const { billId } = row;
      try {
        await this.$post("/biz/new/agent/comp/applyWithdrawn", { billId });
        this.getList();
        this.$message.success("撤回成功");
      } catch (e) {
        this.getList();
        this.$message.error("撤回失败");
      }
    },
    // 确认上传
    async submit() {
      const { billId, remark, docType } = this;
      const params = {
        billId,
        remark,
        docType,
      };
      params["fileList"] = this.uploadfileList;
      try {
        await this.$post("/biz/new/agent/comp/saveFiles", params);
        this.getList();
        this.$message.success("上传成功");
      } catch (e) {
        return;
      }
      this.dialogVisible1 = false;
    },
    // 对话框关闭以后
    handleClosed() {
      this.dialogData = {};
    },
    // 上传对话框关闭
    handleClosed1() {
      this.remark = "";
      this.uploadfileList = [];
    },
    //申请开票
    // applyForInvoicing(row) {
    //   let arr = [row];
    //   this.$router.push({
    //     path: "/web/financialSettlement/invoicingManagementAddEdit",
    //     query: {
    //       settlementInfoData: JSON.stringify(arr),
    //       stu: "settlement",
    //       applicationComp: "10",
    //     },
    //   });
    // },
    // 获取表格高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
          window.innerHeight -
          (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3.25 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 - 100;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        console.log(val.query.refresh);
        if (val.query.refresh == "true" || val.query.refresh) {
          this.getList(this.pagination.current);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style lang="less">
.confirmButton-feifan {
  background-color: #5c6be8 !important;
}
</style>

<style lang="less" scoped>
.seachStyle {
  .searchList {
    .listLabel {
      min-width: 88px !important;
    }
  }
}
.rowdata {
  .el-col {
    border-top: 1px solid #ebeef5;
    padding: 20px;
    border-right: 1px solid #ebeef5;
  }
  display: flex;
  border-left: 1px solid #ebeef5;
}
.rowdata:nth-last-child(1) {
  .el-col {
    border-bottom: 1px solid #ebeef5;
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
.col-title {
  color: #909399;
  font-size: 14px;
  font-weight: bold;
}
</style>
